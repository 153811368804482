import { ServiceRequestDropdown } from "../types/types";

export class FixedServiceRequestTypes {
  static PUBLIC_LITTER_BINS = "PublicLitterBins";
  static DAMAGED_BIN = "DamagedBin";
  static MISSING_BIN = "MissingBin";
  static ADDITIONAL_BIN = "AdditionalBin";
  static GARDEN_WASTE_NEW = "GardenWasteNew";
  static GARDEN_WASTE_CHANGE = "GardenWasteChange";
  static CANCEL_GARDEN_WASTE = "CancelGardenWaste";
  static NEW_PROPERTY_BIN = "NewPropertyBin";
  static CHANGE_BIN_SIZES = "ChangeBinSizes";
  static MISSED_COLLECTION = "MissedCollection";
  static ILLEGAL_DUMPING = "IllegalDumping";
  static LOOSE_LITTER = "LooseLitter";

  static serviceRequestList: ServiceRequestDropdown[] = [
    {
      id: FixedServiceRequestTypes.PUBLIC_LITTER_BINS,
      title: "Report overflowing, smelly, or damaged public litter bins",
      category: "Public bins",
    },
    {
      id: FixedServiceRequestTypes.DAMAGED_BIN,
      title: "Damaged bin",
      category: "Kerbside collections",
    },
    {
      id: FixedServiceRequestTypes.MISSING_BIN,
      title: "Missing bin",
      category: "Kerbside collections",
    },
    {
      id: FixedServiceRequestTypes.ADDITIONAL_BIN,
      title: "Additional bins",
      category: "Kerbside collections",
    },
    {
      id: FixedServiceRequestTypes.GARDEN_WASTE_NEW,
      title: "Garden waste service",
      category: "Kerbside collections",
    },
    {
      id: FixedServiceRequestTypes.NEW_PROPERTY_BIN,
      title: "Request kerbside bins for your new build",
      category: "Kerbside collections",
    },
    {
      id: FixedServiceRequestTypes.CHANGE_BIN_SIZES,
      title: "Change your rubbish and recycling bin sizes",
      category: "Kerbside collections",
    },
    {
      id: FixedServiceRequestTypes.MISSED_COLLECTION,
      title: "Missed collection",
      category: "Kerbside collections",
    },
    {
      id: FixedServiceRequestTypes.ILLEGAL_DUMPING,
      title: "Illegal dumping",
      category: "Other",
    },
    {
      id: FixedServiceRequestTypes.LOOSE_LITTER,
      title: "Loose litter",
      category: "Other",
    },
  ];
}

export class FixedServiceTypes {
  static RUBBISH = "Rubbish";
  static RECYCLING = "Recycling";
  static FOOD = "Food";
  static GLASS = "Glass";
  static GARDEN_WASTE = "GardenWaste";

  static serviceList = {
    [FixedServiceTypes.RUBBISH]: "Rubbish",
    [FixedServiceTypes.RECYCLING]: "Recycling",
    [FixedServiceTypes.FOOD]: "Food",
    [FixedServiceTypes.GLASS]: "Glass",
    [FixedServiceTypes.GARDEN_WASTE]: "Garden waste",
  };

  static additionalBinServiceList = {
    [FixedServiceTypes.FOOD]: "Food scraps (23L) $38",
    [FixedServiceTypes.GLASS]: "Glass (45L) $27",
    [FixedServiceTypes.GARDEN_WASTE]: "Garden waste (240L) $80 or $110",
  };
}

export class FixedPublicLitterTypes {
  static OVERFLOWING_PUBLIC_BINS = "OverflowingPublicBins";
  static ODOROUS_PUBLIC_BINS = "OdorousPublicBins";
  static DAMAGED_PUBLIC_BINS = "DamagedOrVandalisedPublicBins";

  static publicLitterBinsList = {
    [FixedPublicLitterTypes.OVERFLOWING_PUBLIC_BINS]: "Overflowing public bins",
    [FixedPublicLitterTypes.ODOROUS_PUBLIC_BINS]: "Odorous public bins",
    [FixedPublicLitterTypes.DAMAGED_PUBLIC_BINS]: "Damaged or vandalised public bins",
  };
}

export class FixedLooseLitterTypes {
  static RESERVES = "Reserves";
  static CARPARK = "Carpark";
  static WALKWAY = "Walkway";
  static FOOTPATH = "Footpath";
  static BERM = "Berm";
  static OTHER = "Other";

  static looseLitterList = {
    [FixedLooseLitterTypes.RESERVES]: "Reserves",
    [FixedLooseLitterTypes.CARPARK]: "Carpark",
    [FixedLooseLitterTypes.WALKWAY]: "Walkway",
    [FixedLooseLitterTypes.FOOTPATH]: "Footpath",
    [FixedLooseLitterTypes.BERM]: "Berm",
    [FixedLooseLitterTypes.OTHER]: "Other",
  };
}

export const fixedIllegalDumpingDetails = [
  { value: "Furniture", label: "Furniture" },
  { value: "Mattresses", label: "Mattresses" },
  { value: "Household items", label: "Household items" },
  { value: "Rubbish bags", label: "Rubbish bags" },
  { value: "Scrap metal", label: "Scrap metal" },
  { value: "Garden waste", label: "Garden waste" },
  { value: "TV’s", label: "TV’s" },
  { value: "Tyres", label: "Tyres" },
  { value: "Homeless belongings", label: "Homeless belongings" },
  { value: "Pallets", label: "Pallets" },
  { value: "Clothing", label: "Clothing" },
  { value: "Other", label: "Other" },
];

// Handles all entry and exit animations for the service request paths
export const animationVariants = {
  initial: {
    opacity: 0,
    scale: 0.9,
  },
  in: {
    opacity: 1,
    scale: 1,
    transition: {
      ease: "easeInOut",
      duration: 0.2,
      staggerChildren: 0.075,
    },
  },
  out: {
    opacity: 0,
    transition: { duration: 0.075 },
  },
};
